<template>
  <div class="flex h-full">
    <loader :loading="loadingUser" :backdrop="true"/>
    <DetailsSidePanel class="side-panel">
      <template v-slot:heading>resend invitation</template>
      <template v-slot:text>
        <div>Resend invitation via sms or email.</div>
      </template>
    </DetailsSidePanel>
    <div class="flex-1">
      <div v-if="userProfileLoaded">
        <Form
          :submit="sendInvitation"
          :initialValues="initialValues"
          @change="handleFormChange"
        >
          <div>
            <label class="form-section-title mb-4">choose the delivery method:</label>

            <div class="form-row delivery-option">
              <RadioInput name="deliveryMethod" label="email" value="email" />
              <TextField
                name="email"
                :disabled="formValues.deliveryMethod !== 'email'"
                :validate="email"
                :editMode="false"
              />
            </div>
            <div class="form-row delivery-option">
              <RadioInput name="deliveryMethod" label="sms" value="sms" />
              <PhoneInput name="smsPhoneNumber"
                  :disabled="formValues.deliveryMethod !== 'sms'"
                  :validate="phoneNumber"
              />
            </div>
            <ModalFooter
              :footer="{primaryButton:  'send' , tertiaryButton: 'cancel'}"
              :tertiary="close"
              :disablePrimary="sendDisabled"
            />
          </div>
        </Form>
        <BeatLoader class="flex-1 mt-4" :loading="sending"/>
      </div>
    </div>
  </div>
</template>

<script>
  import {isEmpty} from 'lodash-es';
  import ConfirmationMixin from '@/mixins/ConfirmationMixin';
  import ModalNavigation from '@/mixins/ModalNavigation';
  import NotifyMixin from '@/mixins/NotifyMixin'
  import ValidatorMixin from '@/components/form/ValidatorMixin';
  import ModalFooter from '@/components/ui/modals/ModalFooter';
  import Form from '@/components/form/Form';
  import PhoneInput from '@/components/form/PhoneInput';
  import TextField from '@/components/form/TextField';
  import RadioInput from '@/components/form/RadioInput';
  import BeatLoader from '@/components/ui/BeatLoader';
  import Loader from '@/components/ui/Loader';
  import DetailsSidePanel from '@/components/auth/details/DetailsSidePanel';

  export default {
    name: 'QuextUserReinviteView',
    components: {
      DetailsSidePanel,
      ModalFooter,
      Form,
      PhoneInput,
      TextField,
      RadioInput,
      BeatLoader,
      Loader,
    },
    mixins: [
      ConfirmationMixin,
      ModalNavigation,
      NotifyMixin,
      ValidatorMixin,
    ],
    data: function() {
      return {
        loadingUser: true,
        sending: false,
        initialValues: {
          deliveryMethod: 'email',
        },
        formValues: {},
        user: {},
      }
    },
    computed: {
      userId: function () {
        return this.$route.params.id;
      },
      userProfileLoaded() {
        return !isEmpty(this.user);
      },
      sendDisabled() {
        return (this.formValues.deliveryMethod === 'email' && isEmpty(this.formValues.email)) ||
            (this.formValues.deliveryMethod === 'sms' && isEmpty(this.formValues.smsPhoneNumber));
      },
    },
    methods: {
      handleFormChange(state) {
        this.formValues = state.values;
      },

      sendInvitation() {
        const { deliveryMethod, email, smsPhoneNumber } = this.formValues;
        let destination, data = {};

        if (deliveryMethod === 'sms') {
          destination = smsPhoneNumber;
          data = { smsPhoneNumber };
        } else {
          destination = email;
          data = { email };
        }

        this.requestConfirmation({
          confirmationMessage: `resend invitation to ${destination}?`,
          confirmBtnText: 'yes',
          cancelBtnText: 'no',
          confirmationType: 'success'
        })
            .then(confirmed => {
              if (!confirmed) {
                return null;
              }

              this.sending = true;

              return this.$authDataProvider.create('reInviteQuextUser', {id: this.userId, data})
                  .then(() => {
                    this.notifySuccess('invitation has been sent');
                    this.close();
                  })
                  .catch(error => this.notifyError(error.message))
                  .finally(() => {
                    this.sending = false;
                  });
            });
      },

      fetchUser() {
        return this.$authDataProvider.getOne('quextUsers', { id: this.userId })
            .then((result) => {
              const { email, phone } = result;
              this.initialValues = {
                deliveryMethod: email ? 'email' : 'sms',
                smsPhoneNumber: phone,
                email,
              };
              this.user = result;
              this.loadingUser = false;
            })
            .catch((error) => this.notifyError(error.message));
      },
    },
    mounted() {
      this.fetchUser();
    }
  };
</script>

<style scoped>
  .delivery-option {
    flex-direction: column;
    margin-bottom: 2rem;
  }

  .delivery-option :deep(.form-col) {
    margin: 0;
  }
</style>
