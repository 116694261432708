<template>
  <Details
    title="Quext user"
    resource="quextUsers"
    :basePath="basePath"
    :title-template-render="({ deletedAt, fullName }) => `${deletedAt ? 'view' : 'edit'} quext user ${fullName}`"
    breadcrumb-template="{fullName}"
    ref="details"
  >
    <template v-slot:side-panel="{ record }">
      <DetailsSidePanel entity-title="quext user" :view-mode="Boolean(record.deletedAt)"></DetailsSidePanel>
    </template>
    <template v-slot:default="detailsProps">
      <QuextUserForm
        :initialValues="detailsProps.record"
        :on-submit="detailsProps.handleSubmit"
        :loading="detailsProps.loading"
        @close="detailsProps.redirectToList"
      />
    </template>
  </Details>
</template>

<script>
  import QuextUserForm from '@/views/auth/quextUsers/QuextUserForm';
  import Details from '@/components/auth/details/Details';
  import ModalNavigation from '@/mixins/ModalNavigation';
  import NotifyMixin from "@/mixins/NotifyMixin";
  import ActionsMixin from "@/mixins/ActionsMixin";
  import DetailsSidePanel from "@/components/auth/details/DetailsSidePanel";
  import { STATUS } from '@/components/auth/constants';

  export default {
    name: 'QuextUsersEdit',
    mixins: [ModalNavigation, NotifyMixin, ActionsMixin],
    components: {
      Details,
      QuextUserForm,
      DetailsSidePanel
    },
    data() {
      return {
        record: {},
      };
    },
    computed: {
      basePath: function () {
        const view = this.$route.name.includes('deletedQuextUsers') ? 'details' : 'edit';
        return this.$route.name.replace(view, 'index');
      },
      profileId: function() {
        return this.record.profileId;
      }
    },
    methods: {
      resetMfa() {
        this.$authDataProvider.create('resetQuextUserMfa', {id: this.profileId})
            .then(() => this.notifySuccess('MFA setup has been reset'))
            .catch(error => this.notifyError(error.message));
      },
      deleteUser() {
        const record = this.$refs.details.record;
        this.$authDataProvider.delete('quextUsers', {id: record.profileId})
            .then(() => {
              this.notifySuccess(`user ${this.record.fullName} has been deleted`);
              this.close();
            })
            .catch(error => this.notifyError(error.message))
      },
    },
    mounted() {
      this.$watch('$refs.details.record', function (record) {
        this.record = record;
        const {lastLogin, deletedAt, active, mfaRequired, status} = record;

        if (!deletedAt && status.toLowerCase() === STATUS.INVITED) {
          this.addActionToStore({
            routeName: this.$route.name,
            item: {
              id: 'auth-resend-quext-invitation',
              title: 'resend invitation',
              routeName: 'quextUsers.invite.index',
              params: { profileId: '{id}' },
              dynamic: true,
            },
          });
        }

        if (mfaRequired && active && lastLogin && !deletedAt) {
          this.addConfirmAction(
              {
                id: 'auth-reset-quext-mfa',
                title: 'reset mfa setup',
                showConfirmation: true,
                confirmationMessage: `reset multi factor authentication setup for ${record.firstName} ${record.lastName}?`,
                confirmationType: 'success',
                confirmBtnText: 'yes',
                cancelBtnText: 'no'
              },
              this.resetMfa
          );
        }

        if (!deletedAt) {
          this.addConfirmAction(
              {
                id: 'auth-delete-quext-user',
                title: "delete user",
                showConfirmation: true,
                confirmationMessage: 'are you sure you want to delete this user?',
                confirmBtnText: 'yes',
                cancelBtnText: 'no',
                confirmationType: 'warning'
              },
              this.deleteUser
          )
        }
      })
    },
  };
</script>
